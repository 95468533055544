import React from "react"
// import Layout from "./layout"
// import SEO from "./seo"
import URL from "./url"
import Banner from "./banner"
import LightBox from "./lightbox"
import BCAreg from '../assets/images/certs/BCAregistration.svg';
import qualitycert from '../assets/images/certs/ISO9001Cert.svg';
import emscert from '../assets/images/certs/ISO14001Cert.svg';
import ggbscert from '../assets/images/certs/ggbscert.svg';
import seaaward from '../assets/images/certs/SEAcert.jpg';
import bizSafe from '../assets/images/logos/bizSafe.svg';
import nsmark from '../assets/images/logos/NSmark.svg';
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from 'semantic-ui-react';



const LogoWords = ({ data, children }) => (
	<object type="image/svg+xml" data={data} style={{ height: '30px', marginBottom: -10 }}>{children}</object>
);

// 200x BizSafe, OHSAS renewing

// 2014-06-24 SEA
// 2015-04-08 STQB
// 2016 URA
// 2018-01-17 EMS
// 2018-10-31 GGBS
// 2019-01-01 NSmark
// 2020-04-07 ISO9001
// 2020-06-17 B2
const PortfolioPage = ({ path }) => {
  const data = useStaticQuery(graphql`
    query {
      purvis: file(name: {eq: "purvis-2"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      leedon: file(name: {eq: "leedon-1"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      mosque: file(name: {eq: "mosque"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      horseburgh: file(name: {eq: "horseburgh-close"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      gohloo: file(name: {eq: "GohLooClubFront2016Optimised"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      bridge: file(name: {eq: "Civil-bridge1"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      factory: file(name: {eq: "senokosouth2017Optimised"}) {
        childImageSharp {
          fluid(traceSVG: {}) {
            tracedSVG
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);
  const { bridge, gohloo, horseburgh, leedon, mosque, purvis, factory } = data;
  return (
    // <Layout path={path}>
      <div className="timeline page">

        {/* <SEO title={`Portfolio`} /> */}
        <Banner>Our Portfolio</Banner>
        <section id="timeline" className="content description">
          <p>
            With more than 20 years in the construction industry, we have managed to resolve obstacles and problems encountered in the projects that we have undertaken, thereby emerging stronger and more knowledgeable.
          </p>
          <p>
            We have never stopped honing our skills, while we mainly focus on residential market, we are also constantly looking for opportunities to grow our business into the other sectors of the construction industry.
          </p>
          <p>
            Therefore, in the last 5 years, we have gotten additional certifications and upgraded our skillsets. Moreover, we have ventured into building factories and taking on specialized conservation projects such as providing addition and alteration as well as restoration works to any property in need of preservation.
          </p>
        </section>
        <section className="content description" style={{ textAlign: 'center' }}>
          <div className="timeline-main">
            <div id="timeline-line"></div>

            <div className="timeline-year">2020</div>
            <div className="timeline-content">
              <div className="description">
                <h3>June</h3>
                Achieved BCA grade B2 for General Building WorkHead, this increase the Tendering Limit for public projects to SGD 13 million
              </div>
              <div className="image cert">
                <LightBox bordered src={BCAreg} caption={<URL href={`https://www.bca.gov.sg/BCADirectory/Company/Details/199706813H`}>BCA registration</URL>}/>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <h3>April</h3>
                Achieved ISO9001:2015 Certification for Quality Management System
              </div>
              <div className="image cert">
                <LightBox bordered src={qualitycert} caption='ISO 9001:2015 standard for Quality Management System'/>
              </div>
            </div>

            <div className="timeline-year">2019</div>
            <div className="timeline-content">
              <div className="description">
                <h3>July</h3>
                <h4>Selected Highlight - </h4>Started conservation works on the historic <URL href="https://www.straitstimes.com/singapore/singapore-philatelic-museum-peranakan-museum-to-close-for-major-redevelopment-slated-for">Singapore Philatetic Museum</URL>
              </div>
              <div className="image">
                <LightBox bordered src={'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Singapore_Philatelic_Museum%2C_2012.jpg/800px-Singapore_Philatelic_Museum%2C_2012.jpg'} caption='Singapore Philatetic Museum'/>
              </div>
              
            </div>
            <div className="timeline-content">
              <div className="description">
                <h3>January</h3>
                Achieved <LogoWords data={nsmark}>NSMark</LogoWords> recognition of our support for National Service
              </div>
            </div>

            <div className="timeline-year">2018</div>
            <div className="timeline-content">
              <div className="description">
                <h3>October</h3>
                Awarded Green and Gracious Certification (GGBS) by BCA
              </div>
              
              <div className="image cert">
                <LightBox bordered src={ggbscert} caption='Green and Gracious certificate by Building and Construction Authority (BCA)'/>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <h3>May</h3>
                <h4>Selected Highlight - </h4>Started civil engineering works on erecting link bridge at Senoko
              </div>
              <div className="image">
                <LightBox bordered {...bridge.childImageSharp.fluid} caption='Bridge civil engineering works'/>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <h3>January</h3>
                Achieved ISO14001:2015 Certification for Environmental Management System
              </div>
              <div className="image cert">
                <LightBox bordered src={emscert} caption='ISO 14001:2015 standard for Environmental Management Systems'/>
              </div>
            </div>
            <div className="timeline-year">2016</div>
            <div className="timeline-content">
              <div className="description">
                <h3>October</h3>
                Achieved recognition for our conservation effort in restoring the historic Goh Loo Clubhouse with <URL href="https://www.ura.gov.sg/Corporate/Media-Room/Media-Releases/pr16-62">URA Architectural Heritage Award 2016 for restoration</URL>
              </div>
              <div className="image">
                <LightBox bordered {...gohloo.childImageSharp.fluid} caption='Conservation efforts to Goh Loo Club'/>
              </div>
            </div>
            <div className="timeline-year">2015</div>
            <div className="timeline-content">
              <div className="description">
                <h3>April</h3>
                Awarded Singapore Excellence Award
              </div>
              <div className="image cert">
                <LightBox bordered src={seaaward} caption='Singapore Excellence Award 2014'/>
              </div>
            </div>
            <div className="timeline-year">2014</div>
            <div className="timeline-content">
              <div className="description">
                <h3>October</h3>
                <h4>Selected Highlight - </h4>Started work on 6-storey commercial factory in Senoko
              </div>
              <div className="image">
                <LightBox bordered {...factory.childImageSharp.fluid} caption='6-storey commercial factory'/>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <h3>June</h3>
                Awarded Singapore Trusted Quality Brand
              </div>
            </div>

            <h3 className="timeline-year">
              <Link to="/about#awards">See all our awards & certification</Link>
            </h3>

            <div className="spacer"></div>

            <div className="timeline-year">...some time back</div>

            <div className="timeline-content">
              <div className="description">
                <h3>Certification</h3>
                <p>
                  ISO 45001:2018 standard for health & safety in the workplace
                </p>
                <p>
                  <LogoWords data={bizSafe}>BizSAFE STAR</LogoWords> certificate by Workplace Safety and Health (WSH) Council
                </p>
              </div>
            </div>

            <div className="timeline-content">
              <div className="description">
                <h3>Notable Works</h3>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <p>
                  Addition and Alteration of an Existing 2 Storey Conservation Shophouse at Tanjong Katong
                </p>
              </div>
            </div>
            <div className="timeline-content">
              <div className="description">
                <p>
                  Built or renovated several institutional structures like Mosques and Temples all around Singapore
                </p>
              </div>
              <div className="image">
                <LightBox bordered {...mosque.childImageSharp.fluid} caption='Serangoon Mosque'/>
              </div>
            </div>

            <div className="timeline-content">
              <div className="description">
                <p>
                  Built US Embassy Mission Residence of 2-Storey with a Basement and Ancillary Facilities <span>(Built by our experienced senior management)</span>
                </p>
              </div>
              <div className="image">
                <LightBox bordered {...leedon.childImageSharp.fluid} caption='Leedon Park US Embassy Mission Residence'/>
              </div>
            </div>
            
            <div className="timeline-content">
              <div className="description">
                <p>
                  Conservation of Existing 2-Storey Shophouse and Additions and Alterations at Purvis Street for M/s British & Malayan Trustees Ltd <span>(Built by our experienced senior management)</span>
                </p>
              </div>
              <div className="image">
                <LightBox bordered {...purvis.childImageSharp.fluid} caption='Purvis Street shophouses'/>
              </div>
            </div>
            
            <div className="timeline-content">
              <div className="description">
                <p>
                  Restoration to Existing 2-Storey Pre-War House at No.73, No.75 and No.79 at Victoria Street, Singapore <span>(Built by our experienced senior management)</span>
                </p>
              </div>
            </div>
            
            <div className="timeline-content">
              <div className="description">
                <p>
                  Development of Horseburgh Lighthouse <span>(Built by our experienced senior management)</span>
                </p>
              </div>
              <div className="image">
                <LightBox bordered {...horseburgh.childImageSharp.fluid} caption='Horseburgh Lighthouse'/>
              </div>
            </div>

          </div>
        </section>
        <div className="action">
          <Button basic color='red' as={Link} to={'/contact/'}>Like what you see?</Button>
        </div>
      </div>
    // </Layout>
  )
}
export default PortfolioPage;