import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import URL from "../components/url"
import { Grid, Button, Divider, Image } from 'semantic-ui-react';
import { Link } from "gatsby"
import LightBox from '../components/lightbox'

import logosquare from '../assets/images/logos/logosquare.svg'
// import logo from '../assets/images/logo.svg'
import PortfolioPage from '../components/portfolio'
import uraaward from '../assets/images/certs/AHAplaque.svg';
import nsmarkcert from '../assets/images/certs/NSmarkCert.svg';
import bizSafecert from '../assets/images/certs/bizSafeStarCert2024.svg';
import ggbscert from '../assets/images/certs/ggbscert.svg';
import gb1cert from '../assets/images/certs/GB1Cert.svg';
import BCAreg from '../assets/images/certs/BCAregistration.svg';
import qualitycert from '../assets/images/certs/ISO9001Cert.svg';
import ohsascert from '../assets/images/certs/ISO45001Cert.svg';
import emscert from '../assets/images/certs/ISO14001Cert.svg';
import seaaward from '../assets/images/certs/SEAcert.jpg';
import stqbaward from '../assets/images/certs/STQBcert.jpg';
import useViewPort from '../utils/useViewPort';


const Mission = () => {
  const { isMobile } = useViewPort();
  return (
    <section className="mission content">
      <Grid columns={isMobile ? 1 : 2}>
        <Grid.Column>
          <h3>Our Mission</h3>
          <p>“We are passionate about making your visions a reality.”.</p>
          <p>Our mission is to establish a strong foundation in the construction industry by delivering on our promises to our clients and providing them with a sense of security in entrusting JINMAC to construct a comfortable living or working environment.</p>
        </Grid.Column>
        <Grid.Column>
          <h3>Our Expertise</h3>
          <p>With about 20 years in this industry, we have managed to resolve obstacles and problems encountered in the projects that we have undertaken, thereby emerging stronger and more knowledgeable.</p>
          <p>As a result, we are able to provide our client, both owner and consultant, timely advise of potential problems in advance so as to avoid costly rectification works. It is with this skill that we hope to be able to add value to our venture both domestically and other Asia country such as Myanmar’s construction industry.</p>
        </Grid.Column>
      </Grid>
    </section>
  );
};
const Values = () => {
  const { isMobile } = useViewPort();
  return (
    <section className="values content">
      <h2>Our Corporate Values</h2>
      <Divider/>
      <Grid columns={isMobile ? 1 : 3}>
        <Grid.Column>
          <h3>Client Satisfaction</h3>
          <p>To our client, we aim to deliver quality building that meet their needs &amp; expectations as well as fostering long-term relationships.</p>
        </Grid.Column>
        <Grid.Column>
          <h3>Productive Workforce</h3>
          <p>To our employees, we hope to instill in them care and passion in their works and use their skill to the best of their ability. See our <Link to={'/greenpolicy/'}>company policy on graciousness</Link>.</p>
        </Grid.Column>
        <Grid.Column>
          <h3>Sustainable Practices</h3>
          <p>To the environment, we shall strive to utilize methods of construction that minimize damage to the environment. See our <Link to={'/greenpolicy/'}>company policy on the environment</Link>.</p>
        </Grid.Column>
      </Grid>
      <div className="action">
        <Button basic color='red' as={Link} to={'/contact/'}>Contact us</Button>
      </div>
    </section>
  );
};

const BCAURL = ({ children }) => <URL href={`https://www.bca.gov.sg/BCADirectory/Company/Details/199706813H`}>{children}</URL>

const Awards = () => (
  <section className="content awards" id="awards">
    <Divider />
    <h2>Our Awards &amp; Certification</h2>
    <Grid columns={3}>
      <Grid.Column>
        <LightBox bordered src={gb1cert} caption={<BCAURL>General Builder Class 1 License</BCAURL>}/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={BCAreg} caption={<BCAURL>BCA registration</BCAURL>}/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={uraaward} caption='URA Architectural Heritage Award 2016 for restoration'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={qualitycert} caption='ISO 9001:2015 standard for Quality Management System'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={bizSafecert} caption='bizSafe Star certificate by Workplace Safety and Health (WSH) Council'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={ggbscert} caption='Green and Gracious certificate by Building and Construction Authority (BCA)'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={ohsascert} caption='ISO 45001:2018 standard for health & safety in the workplace'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={emscert} caption='ISO 14001:2015 standard for Environmental Management Systems'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={seaaward} caption='Singapore Excellence Award 2014'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={stqbaward} caption='Singapore Trusted Quality Brand Award 2015'/>
      </Grid.Column>
      <Grid.Column>
        <LightBox bordered src={nsmarkcert} caption='NS Mark'/>
      </Grid.Column>
    </Grid>
  </section>
)

const AboutPage = (params) => {
  return (
    <Layout {...params}>
      <div className="about page">

        <SEO title="About" />
        <Banner>About us</Banner>
        <section className="content description">
          <Image src={logosquare} style={{ margin: '10px auto', height: 120 }}/>
          <p>We are a locally reputable construction company with over 20 years experience that specialises in building reconstruction and addition &amp; alterations works (A&amp;A) in Singapore.</p>
          <p>We are <BCAURL>registered</BCAURL> with the Building and Construction Authority (BCA) under the workhead CW01 General Building with Financial Registration Grade B2 <URL href={`https://www.bca.gov.sg/contractorsregistry/contractors_tendering_limits.html`}>(Tendering Capacity SGD$13 million)</URL> and CW02 Civil Engineering with Grade C1 (Tendering Capacity SGD$4 million)</p>
          <p>Jinmac Pte Ltd was established in 1997 as a general builder with a small office in Shawn Road. At that time, our main source of income was from maintenance work as we were new in the market.</p>
          <p>Steadily we delivered on our promises to our clients and they have in turn ensured our existence through continual referrals of their family, friends and colleagues. We are now	a <BCAURL>GB1 licensed contractor</BCAURL> registered with	“Building &amp; Construction Authority” (BCA) and will continue to build on this trust and uphold our quality standard for our clients.</p>
          <p>With our continual improvements, we are also <Link to={'/about#awards'}>ISO9001, ISO45001, ISO14001, bizSAFE, Green &amp; Gracious (GGBS) certified</Link>.</p>
          <p>Our principle business activities is the building of residential landed properties such as terrace, semi-detached and bungalow.</p>
          <h4 style={{ textAlign: 'center' }}>TURN EACH HOMEOWNER’S DREAM INTO REALITY</h4>
          <p>In the land-constrained country that is Singapore, the high cost of living limits the extravagance of building each home owners' dream home. For Jinmac Pte Ltd, it is our life mission to turn dreams into reality.</p>
          <p>Our success is due to our reasonable quotes, but more importantly in our fine work and quality craftsmanship that captures the hearts of our clients. Their appreciation and referrals are what pulled Jinmac from a humble single man company to its current glory.</p>
          <p>As we continue to hone our skills in the residential market, we are also constantly looking for opportunities to grow our business into the other sectors of the construction industry.</p>
          <p>Therefore, in the last 5 years, we have ventured into building factories and taking on specialized conservation projects such as providing addition and alteration as well as restoration works to any property in need of preservation.</p>
          <div className="action"><Button basic color='red' as={Link} to={'/services#featured'}>View our portfolio</Button></div>
        </section>
        <Mission />
        <Values />
        <PortfolioPage />
        <Awards />
      </div>

    </Layout>
  );
};

export default AboutPage;